@font-face {
  font-family: Funky;
  src: url(../fonts/FunkyRundkopfNF.ttf);
}
@font-face {
  font-family: FunkyDuo;
  src: url(../fonts/FunkyRundkopfTwoNF.ttf);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/images/load.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.giphy-embed {
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  background-size: cover;
}

.video {
  width: 100vw;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
}

.home {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f1f1f1;
  font-family: FunkyDuo, sans-serif;
}
.home .heading {
  font-size: 10rem;
}
@media screen and (max-width: 1300px) {
  .home .heading {
    font-size: 8rem;
  }
}
@media screen and (max-width: 1100px) {
  .home .heading {
    font-size: 6rem;
  }
}
@media screen and (max-width: 900px) {
  .home .heading {
    font-size: 3rem;
  }
}
.home .subheading {
  font-size: 3rem;
}
@media screen and (max-width: 1300px) {
  .home .subheading {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1100px) {
  .home .subheading {
    font-size: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .home .subheading {
    font-size: 1rem;
  }
}
.home .subheading a {
  color: #ffffff;
  text-decoration: none;
}
.home .countdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 10vh;
}
@media screen and (max-width: 900px) {
  .home .countdown h1 {
    font-size: 1rem;
  }
}
.home .countdown .counter {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.home .countdown .counter p {
  font-size: 3rem;
  color: #ffffff;
}
@media screen and (max-width: 900px) {
  .home .countdown .counter p {
    font-size: 1rem;
  }
}
.home .countdown .counter span {
  font-size: 3rem;
  color: #fff;
}
@media screen and (max-width: 900px) {
  .home .countdown .counter span {
    font-size: 1rem;
  }
}/*# sourceMappingURL=index.css.map */